<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'User Id'" :value="'#'+userId" />
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

          <template slot="header">
            <div class="detail-page__head">
              <div class="detail-page__head-line">
                <div class="detail-page__header-col">
                  <router-link :to="$store.getters.GET_PATHS.settingsUsers" class="detail-page__head-back">
                    <LinkBack
                            :value="'Back to Users'"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </template>

        <template slot="body">
          <div class="registration-form__section">
            <div class="registration-form__title section-label">
              Personal Information
            </div>
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.name}"
                          :error="validation.name"
                          :errorTxt="serverError ? validationTxt.name : 'This field is required'"
                          :label="'Name'"
                          :type="'text'"
                          v-model="name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.surName}"
                          :error="validation.surName"
                          :errorTxt="serverError ? validationTxt.surName : 'This field is required'"
                          :label="'Surname'"
                          :type="'text'"
                          v-model="surName"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.phone}"
                          :error="validation.phone"
                          :errorTxt="serverError ? validationTxt.phone : 'This field is required'"
                          :label="'Phone'"
                          :type="'text'"
                          v-model="phone"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.email}"
                          :error="validation.email"
                          :errorTxt="serverError ? validationTxt.email : 'This field is required'"
                          :label="'Email'"
                          :type="'email'"
                          v-model="email"
                  />
                </div>
              </div>

            </div>
          </div>

          <div class="registration-form__section">
            <div class="registration-form__title section-label">
              Location
            </div>
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.address}"
                          :error="validation.address"
                          :errorTxt="serverError ? validationTxt.address : 'This field is required'"
                          :label="'Address'"
                          :type="'text'"
                          v-model="address"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.city}"
                          :error="validation.city"
                          :errorTxt="serverError ? validationTxt.city : 'This field is required'"
                          :label="'City'"
                          :type="'text'"
                          v-model="city"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultSelect
                          v-bind:class="{'ui-no-valid': validation.selectedCountry}"
                          :error="validation.selectedCountry"
                          :errorTxt="serverError ? validationTxt.selectedCountry : 'This field is required'"
                          :options="options"
                          @change="changeCountry"
                          :selected="selectedCountry.name"
                          :label="'Country'"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultSelect
                          v-bind:class="{'ui-no-valid': validation.stateDistrict}"
                          :error="validation.stateDistrict"
                          :errorTxt="serverError ? validationTxt.stateDistrict : 'This field is required'"
                          :options="optionsDistrict"
                          @change="changeDistrict"
                          :selected="stateDistrict.name"
                          :label="'Regions'"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.postNumber}"
                          :error="validation.postNumber"
                          :errorTxt="serverError ? validationTxt.postNumber : 'This field is required'"
                          :label="'Post Number'"
                          :type="'text'"
                          v-model="postNumber"
                  />
                </div>
              </div>
            </div>
          </div>



          <div class="registration-form__section">
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Account Details
                </div>

                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.loginShop}"
                          :error="validation.loginShop"
                          :errorTxt="serverError ? validationTxt.loginShop : 'This field is required'"
                          :label="'Login / Shop'"
                          :type="'text'"
                          v-model="loginShop"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Fullfilmetn (choose at least 1 option)

                  <span class="registration-form__title section-label-prompt" v-if="customValidation.fullFilment">
                This field is required
              </span>
                </div>
                <div class="registration-form__inputs-row custom-row">
                  <div class="registration-form__inputs-col custom-col custom-col--50">
                    <DefaultCheckbox
                            :label="'By Amazon'"
                            v-model="checkedAmazon"
                            :value="checkedAmazon"
                    />
                  </div>
                  <div class="registration-form__inputs-col custom-col custom-col--50">
                    <DefaultCheckbox
                            :label="'By Merchant'"
                            v-model="checkedMerchant"
                            :value="checkedMerchant"
                            :helpText="'Ebay, Etsy, Online Store'"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="registration-form__section">
            <div class="registration-form__title section-label">
              Roles & Permissions
            </div>


            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">

                <div class="registration-form__input">
                  <DefaultSelect
                          :options="rolesOptions"
                          :optionsLabel="'name'"
                          :label="'Role'"
                          @change="changeSelect"
                          v-model="roles"
                          :selected="roles"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <MultiSelect
                          v-bind:class="{'ui-no-valid': validation.permissions}"
                          :error="validation.permissions"
                          :errorTxt="serverError ? validationTxt.permissions : 'This field is required'"
                          :options="permissionsOptions"
                          :optionsLabel="'display_name'"
                          :selected="permissions"
                          :label="'permissions'"
                          @change="changePermissions"
                  />
                </div>
              </div>
            </div>

          </div>
        </template>



        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="$store.getters.GET_PATHS.settingsUsers"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>
            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                      class="detail-page__footer-btn-i"
                      :value="'Change'"
                      @click.native="updateUser"
                      v-bind:class="{'disabled-btn' : $store.getters.getUserBtn}"
              />
            </div>
          </div>
        </template>





      </CardRightBlock>
      <!--<UserPage/>-->
    </div>
  </div>
</template>

<script>
  import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
  import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'
  // import UserPage from './UserPage/UserPage.vue'

  import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

  import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
  import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultSelect from '../../../../../UI/selectiones/DefaultSelect/DefaultSelect.vue'

  import {validation} from "../../../../../../services/validation";

  import DefaultCheckbox from '../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue'
  import MultiSelect from "../../../../../UI/selectiones/MultiSelect/MultiSelect";
  import {filterCountries} from "../../../../../../services/filterCountries";

  // const { Query } = require("cogent-js/src/index");

  var _ = require('lodash');

  export default {
    name: "UsersCardPage",


    components: {
      CardLeftBlock,
      CardRightBlock,
      DefaultInput,
      LinkBack,
      MainButton,
      DefaultSelect,
      DefaultCheckbox,
      MultiSelect,
    },



    data() {

      return {

        userId: '',

        constValue: {
          AMAZON: 'amazon',
          MERCHANT: 'merchant',
          FUNCTIONALITY: {
            FULL: 'full',
            EASY: 'easy',
          },
        },

        successForm: false,
        errorForm: false,
        serverError: false,

        roles: '',
        rolesOptions: [],
        permissions: [],
        permissionsOptions: [],

        name: '',
        surName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        country: '',
        postNumber: '',
        loginShop: '',
        createPassword: '',
        checkedAmazon: false,
        checkedMerchant: false,
        checkedFunctionality: 'full',
        countrySelect: '',
        countrySelectModel: '',
        selectedCountry: '',
        stateDistrict: '',
        tooltipFunctionalityActive: true,

        validation: {
          name: false,
          surName: false,
          phone: false,
          email: false,
          createPassword: false,
          address: false,
          city: false,
          selectedCountry: false,
          stateDistrict: false,
          postNumber: false,
          loginShop: false,
        },

        validationTxt: {
          name: false,
          surName: false,
          phone: false,
          email: false,
          createPassword: false,
          address: false,
          city: false,
          selectedCountry: false,
          stateDistrict: false,
          postNumber: false,
          loginShop: false,
        },

        customValidation: {
          fullFilment: false,
        },

        options: [{}],

        optionsDistrict: [{}],
      }
    },

    mounted() {

      this.$store.dispatch('fetchRoles').then(() => {
        this.rolesOptions = this.$store.getters.getRoles
      })

      // this.$store.dispatch('fetchPermissions').then(() => {
      //   this.permissionsOptions = this.$store.getters.getPermissions;
      // })


      this.$store.dispatch('fetchUserItem', this.$route.params.id).then(() => {
        let user = this.$store.getters.getUserItem


        this.name =  user.contacts[0].name
        this.surName = user.contacts[0].last_name
        this.phone = user.contacts[0].phone
        this.email = user.contacts[0].email
        this.address = user.contacts[0].address
        this.city = user.contacts[0].city
        this.selectedCountry = user.contacts[0].country
        this.postNumber = user.contacts[0].zip
        this.loginShop = user.contacts[0].company_name
        this.checkedAmazon = user.fulfilment_by_amazon === 1 ? true : false
        this.checkedMerchant = user.fulfilment_by_merchant === 1 ? true : false
        this.stateDistrict = user.contacts[0].region

        this.roles = user.roles

        this.$store.dispatch('getRegionsByCountry', user.contacts[0].country_id).then(() => {
          this.optionsDistrict = _.toArray(this.$store.getters.getRegions);
        });

        this.getPermissions(user.roles[0].id)

      })

      this.userId = this.$route.params.id;

      this.$store.dispatch('fetchCountries').then(() => {
        let countries = _.toArray(this.$store.getters.getCountries);
        let filteredCountries = filterCountries(countries)

        this.options = filteredCountries.countries
      })

    },

    methods: {

      getPermissions(id) {
        // Set query URL
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('example')
        myQuery.where('permissionGroups.role_id', id)
        url = url + myQuery.url().split('?')[1]

        this.$store.dispatch('getPermissions', url).then((response) => {
          this.permissionsOptions = response
          this.permissions = response
        })
      },

      changeSelect(item) {
        this.roles = item;
        this.getPermissions(item.id)
      },

      changePermissions(items) {
        this.permissions = items;
      },

      changeCountry(val) {
        if(val === '') return this.optionsDistrict = ''
        this.selectedCountry = val;

        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrict = _.toArray(this.$store.getters.getRegions);
        });
      },

      changeDistrict(val) {
        this.stateDistrict = val;
      },

      changeFunctionality(val) {
        this.checkedFunctionality = val;
      },

      updateUser(e) {
        e.preventDefault();
        let validationItems = {
          name: this.name,
          surName: this.surName,
          phone: this.phone,
          email: this.email,
          address: this.address,
          city: this.city,
          selectedCountry: this.selectedCountry,
          stateDistrict: this.stateDistrict,
          postNumber: this.postNumber,
          loginShop: this.loginShop,
        }


        let validationOptions = {
          name: {
            type: [
              'empty',
            ]
          },
          surName: {
            type: [
              'empty',
            ]
          },
          phone: {
            type: [
              'empty',
              'phone',
            ]
          },
          email: {
            type: [
              'empty',
              'email',
            ]
          },
          createPassword: {
            type: [
              'empty',
              'password',
            ]
          },
          address: {
            type: [
              'empty',
            ]
          },
          city: {
            type: [
              'empty',
            ]
          },
          selectedCountry: {
            type: [
              'empty',
            ]
          },
          stateDistrict: {
            type: [
              'empty',
            ]
          },
          postNumber: {
            type: [
              'numeric',
              'empty',
            ]
          },
          loginShop: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        if (!this.checkedAmazon && !this.checkedMerchant) {
          this.customValidation.fullFilment = true;
        } else {
          this.customValidation.fullFilment = false;
        }

        if (validate.isValidate && !this.customValidation.fullFilment) {
          this.serverError = false;

          let permissionsIds = [];

          this.permissions.map((item) => {
            permissionsIds.push(item.id);
          })


          let data = {

            'phone': this.phone,

            'email': this.email,
            'password': 'sergolskiy',
            'password_confirmation': 'sergolskiy',

            'address': this.address,
            'city': this.city,
            'zip': this.postNumber,

            'fulfilment_by_amazon': this.checkedAmazon ? 1 : 0,
            'fulfilment_by_merchant': this.checkedMerchant ? 1 : 0,

            'functionality_type': 'full',

            'terms': 1,
            "contacts" : {
              "name" : this.name,
              "last_name" : this.surName,
              "middle_name" : "",
              "company_name" : this.loginShop,
              "first_name" : this.name,
              "country_id" : this.selectedCountry.id,
              "country_region_id" : this.stateDistrict.id,
              "email" : this.email
            },

            'role_id': this.roles.id,
            'permission_ids': permissionsIds,
          };



          this.$store.commit('changeUser');

          this.$store.dispatch('updateUsers', {id: this.userId, data: data}).then((response) => {

            if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$store.commit('successUser');

            } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.$store.commit('successUser');
              this.serverError = true;

              errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
              errors.last_name ? (this.validation.surName = true, this.validationTxt.surName = errors.last_name[0]) : false;
              errors.phone ? (this.validation.phone = true, this.validationTxt.phone = errors.phone[0]) : false;
              errors.email ? (this.validation.email = true, this.validationTxt.email = errors.email[0]) : false;
              errors.address ? (this.validation.address = true, this.validationTxt.address = errors.address[0]) : false;
              errors.city ? (this.validation.city = true, this.validationTxt.city = errors.city[0]) : false;
              errors.country_id ? (this.validation.selectedCountry = true, this.validationTxt.selectedCountry = errors.country_id[0]) : false;
              errors.company_name ? (this.validation.loginShop = true, this.validationTxt.loginShop = errors.company_name[0]) : false;

              // this.errorForm = _.toArray(response.response.data.data.errors);
            }

          });

        }

      },

      handleSdkInit({FB, scope}) {
        this.FB = FB;
        this.scope = scope;
      },

      loginFb() {
        this.getUserData()
      },

      getUserData() {
        this.FB.api(
          '/me',
          {fields: 'id, name, picture, email'},
          (user) => {
            this.useFB = true;
            this.name = user.name;
            this.email = user.email;
          }
        )
      },

      changeOrderCreateSwitch(data){
        this.checkedOrderCreateSwitch = data;
      },

      changeProductNameEn(){
        console.log('changeProductNameEn')
      },

      changeProductNameUa(){
        console.log('changeProductNameUa')
      }


    }

  }
</script>

<style lang="scss">

</style>
