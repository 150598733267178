<template>
  <div class="auth-body google-auth">

    <SimpleAlert
        class="auth-body__alert"
        :color="'green'"
        :type="'success'"
        :text="$t('login_emailVerified.localization_value.value')"
    />

    <div class="auth-body__form">
      <div class="auth-form"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'login_emailVerified',
          'profile_verifyYourEmail',
          'profile_pleaseEnterYourCode',
          'profile_verificationCode',
          'profile_toRequestNewCode',
          'profile_requestCodeAgain',
          'profile_sec',
          'profile_Send',
        ])"></span>

        <div class="auth-form__inner">
          <div class="auth-form__title">
            {{ $t('profile_verifyYourEmail.localization_value.value') }}
          </div>
          <div class="auth-form__subtitle">
            {{ $t('profile_pleaseEnterYourCode.localization_value.value') }}
          </div>

          <div class="auth-form__field">
            <WhiteInput
                v-model="code"
                v-bind:class="{'ui-no-valid': validation.code}"
                :error="validation.code"
                :errorTxt="validationTxt.code"
                :label="$t('profile_verificationCode.localization_value.value')"
            />
          </div>

          <div class="auth-form__right-link">
            <template v-if="requestCodeAgain == true">
              {{ $t('profile_toRequestNewCode.localization_value.value') }}
              200{{ $t('profile_sec.localization_value.value') }}.
            </template>
            <span class="site-link"
                  v-if="resend "
            >
              {{ $t('profile_requestCodeAgain.localization_value.value') }}
            </span>
            <span class=""
                  v-if="!resend"
            >
              {{ $t('profile_toRequestNewCode.localization_value.value') }} {{
                time
              }} {{ $t('profile_sec.localization_value.value') }}.
            </span>
          </div>

          <div class="auth-form__btn">
            <div class="auth-form__btn-login">
              <FullButton
                  :value="$t('profile_Send.localization_value.value')"
                  class="google-auth__email-btn"
                  v-bind:class="{'disabled-btn' : $store.getters.getToFactorCodeBtn}"
              />
            </div>
          </div>

          <!--<div class="authentication__click-here">-->
          <!--Already have an account?-->
          <!--<router-link :to="'/#'" class="site-link">-->
          <!--Log In here-->
          <!--</router-link>-->
          <!--</div>-->
        </div>


      </div>


      <div class="auth-form"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'profile_addGoogleAuth',
          'profile_addGoogleAuthTxt',
        ])"></span>

        <div class="auth-form__inner">
          <div class="auth-form__title">
            {{ $t('profile_addGoogleAuth.localization_value.value') }}
          </div>
          <div class="auth-form__subtitle">
            {{ $t('profile_addGoogleAuthTxt.localization_value.value') }}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--50">
              <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></span>
                <MainButton
                    class="wfc"
                    :value="$t('common_goToDashboard.localization_value.value')"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['menu_Profile'])"></span>
              <MainButton
                  class="wfc"
                  :value="$t('menu_Profile.localization_value.value')"
              />
            </div>
          </div>


<!--          <div class="authentication__click-here"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <span class="admin-edit" @click="editTranslate(['menu_Profile'])"></span>-->
<!--            <div class="site-link"-->
<!--                 @click="goToProfile"-->
<!--            >-->
<!--              {{$t('menu_Profile.localization_value.value')}}-->
<!--            </div>-->
<!--          </div>-->


        </div>


      </div>

    </div>

    <BottomLine/>
  </div>

</template>

<script>

import SimpleAlert from "../../../../../../UI/alerts/SimpleAlert/SimpleAlert.vue";
import FullButton from "../../../../../../UI/buttons/FullButtons/FullButton.vue";
import WhiteInput from "../../../../../../UI/inputs/WhiteInput/WhiteInput.vue";
import BottomLine from "../../../../../../coreComponents/BottomLine/BottomLine.vue";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";


export default {

  name: "EmailAuthenticationUser",
  components: {
    MainButton,
    SimpleAlert,
    WhiteInput,
    FullButton,
    BottomLine,
  },

  mixins: [checkAccess],

  data() {
    return {
      code: '',
      time: 0,
      resend: false,
      defaultTimeCheck: 180,
      openAlert: false,
      openErrorAlert: false,
      errorForm: false,
      serverError: false,
      requestCodeAgain: false,

      validation: {
        code: false,
      },

      validationTxt: {
        code: false,
      },

      addGoogleAuth: false,
    }
  },

  mounted() {


  },

  beforeDestroy() {
    // localStorage.removeItem('email2FactorTimer')
  },

  methods: {



  },

}

</script>

<style lang="scss">

</style>
