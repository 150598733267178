<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'Translation Id'" :value="'#1'"/>
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

        <template slot="header">
          <div class="detail-page__head">
            <div class="detail-page__head-line">
              <div class="detail-page__header-col">
                <router-link :to="$store.getters.GET_PATHS.settingsTranslations" class="detail-page__head-back">
                  <LinkBack
                      :value="'Back to Translations'"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="registration-form__section">

            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Localization group
                </div>
                <div class="registration-form__input">
                  <DefaultSelect
                      :options="translationGroupsOptions"
                      :optionsLabel="'name'"
                      :label="'Group'"
                      @change="changeSelect"
                      :selected="translationGroup"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Key
                </div>
                <div class="registration-form__input">
                  <DefaultInput
                      :label="'Key translate'"
                      v-model="key"
                  />
                </div>
              </div>

            </div>


            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  English
                </div>
                <div class="registration-form__input">
                  <TextareaDefault
                      :label="'English translate'"
                      v-model="english"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Ukrainian
                </div>
                <div class="registration-form__input">
                  <TextareaDefault
                      :label="'Ukrainian translate'"
                      v-model="ua"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Russian
                </div>
                <div class="registration-form__input">
                  <TextareaDefault
                      :label="'Russian translate'"
                      v-model="ru"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Latvian
                </div>
                <div class="registration-form__input">
                  <TextareaDefault
                      :label="'Latvian translate'"
                      v-model="lv"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__title section-label">
                  Polish
                </div>
                <div class="registration-form__input">
                  <TextareaDefault
                      :label="'Polish translate'"
                      v-model="pl"
                  />
                </div>
              </div>

            </div>
          </div>


        </template>


        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="$store.getters.GET_PATHS.settingsTranslations"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                  class="detail-page__footer-btn-i"
                  :value="'Change'"
                  @click.native="changeTranslation"
                  v-bind:class="{'disabled-btn' : $store.getters.getTranslationBtn}"
              />
            </div>
          </div>
        </template>


      </CardRightBlock>

    </div>
  </div>
</template>

<script>
import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'

import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'
// import MultiSelect from '../../../../../UI/selectiones/MultiSelect/MultiSelect.vue'

// import {validation} from "../../../../../../services/validation";
import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";


var _ = require('lodash');

export default {
  name: "TranslationsCardPage",


  components: {
    CardLeftBlock,
    CardRightBlock,
    DefaultInput,
    LinkBack,
    MainButton,
    TextareaDefault,
    DefaultSelect,
  },


  data() {

    return {
      successForm: false,
      errorForm: false,
      serverError: false,

      translationId: '',
      translationItem: '',
      english: '',
      ua: '',
      ru: '',
      lv: '',
      pl: '',
      translationGroupsOptions: [],
      translationGroup: {},
      key: '',

      validation: {},

      validationTxt: {},

      forPage: 1000,
      page: 1,
    }
  },

  mounted() {

    const query = new this.Query();
    let url = '?';
    let myQuery = query
        .for('posts')

    url = url + myQuery.limit(this.forPage).page(this.page).url().split('?')[1]

    this.$store.dispatch('fetchTranslationsGroups', url).then(() => {
      this.translationGroupsOptions = this.$store.getters.getTranslationsGroups
    })

    this.$store.dispatch('fetchTranslationItem', this.$route.params.id).then(() => {
      let translationItem = this.$store.getters.getTranslationItem
      this.translationItem = translationItem
      this.english = translationItem.localization_values[0].value
      this.ua = translationItem.localization_values[1].value
      this.ru = translationItem.localization_values[2].value
      this.lv = translationItem.localization_values[3].value
      this.pl = translationItem.localization_values[4].value
      this.translationGroup = translationItem.localization_group
      this.key = translationItem.key

    })

    this.translationId = this.$route.params.id;
  },

  methods: {

    changeSelect(item) {
      this.translationGroup = item;
    },

    changeTranslation() {
      // let validationItems = {
      //   name: this.name,
      //   description: this.description,
      //   display_name: this.display_name,
      // }

      // let validationOptions = {
      //   name: {
      //     type: [
      //       'empty',
      //     ]
      //   },
      //   description: {
      //     type: [
      //       'empty',
      //     ]
      //   },
      //   display_name: {
      //     type: [
      //       'empty',
      //       'display_name',
      //     ]
      //   },
      // };

      // let validate = validation(validationItems, validationOptions);
      // this.validation = validate.validation;

      // if (validate.isValidate) {
      this.serverError = false;


      let data = {
        "key": this.key,
        "group_id": this.translationGroup.id,
        "translations": [
          {
            "1": this.english,
            "2": this.ua,
            "3": this.ru,
            "4": this.lv,
            "5": this.pl,
          }
        ]
      };

      this.$store.commit('changeTranslation');

      this.$store.dispatch('updateTranslations', {id: this.translationId, data: data}).then((response) => {

        if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.$store.commit('successTranslation');

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          // let errors = response.response.data.errors;

          this.serverError = true;

          // errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
          // errors.description ? (this.validation.description = true, this.validationTxt.description = errors.description[0]) : false;
          // errors.display_name ? (this.validation.display_name = true, this.validationTxt.display_name = errors.display_name[0]) : false;
        }

        this.$store.commit('successTranslation');

      });

      // }

    },

  }

}
</script>

<style lang="scss">

</style>
