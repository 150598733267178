import MainButton from '../../../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultInput from '../../../../../../UI/inputs/DefaultInput/DefaultInput.vue'
import ShowMore from '../../../../../../coreComponents/Buttons/ShowMore/ShowMore'
import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
import EditBlack from '../../../../../../../../public/img/common/edit-black.svg?inline'
import DeliveredIco from '../../../../../../../../public/img/common/status/delivered.svg?inline'
import DefaultCheckbox from '../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue'
import LinkButton from '../../../../../../UI/buttons/LinkButton/LinkButton.vue'
import ManagerButton from '../../../../../../UI/buttons/ManagerButton/ManagerButton.vue'


export default {
	name: "UsersTable",

	components: {
		MainButton,
		DefaultInput,
		ShowMore,
		ExportBtn,
		EditBlack,
		DeliveredIco,
		DefaultCheckbox,
		LinkButton,
		ManagerButton
	},

	data() {
		return {
			orderId: '',
		}
	},

	methods: {
		deleteItem(id) {
			this.$store.dispatch('deleteRoles', id).then(() => {
				setTimeout(() => {
					this.openNotify('success', 'common_notificationRecordDeleted')
				}, 300)

				this.$emit('getRoles')
			})
		}
	}
}
