<template>
  <div>
    <UsersHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <UsersFilter :showFilter="showFilter" @close="closeFilter"/>
      <UsersTable @getUsers="getUsers"/>
    </div>
  </div>

</template>

<script>
  import UsersHead from './UsersHead/UsersHead.vue'
  import UsersFilter from './UsersFilter/UsersFilter.vue'
  import UsersTable from './UsersTable/UsersTable.vue'

  export default {
    name: "UsersTablePage",

    components: {
      UsersHead,
      UsersFilter,
      UsersTable,
    },

    data() {
      return {
        showFilter: false,
      }
    },

    mounted() {
      if(this.isAdmin && this._.has(this.currentPermissions, this.PERMISSIONS.USERS_GENERAL)){
        this.$store.dispatch('fetchUsers')
      }
      else {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getUsers() {
        this.$store.dispatch('fetchUsers')
      }
    }
  }
</script>

<style scoped>

</style>
