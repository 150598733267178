<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'Role Id'" :value="'#1'" />
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

        <template slot="header">
          <div class="detail-page__head">
            <div class="detail-page__head-line">
              <div class="detail-page__header-col">
                <router-link :to="$store.getters.GET_PATHS.settingsRoles" class="detail-page__head-back">
                  <LinkBack
                          :value="'Back to Roles'"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="registration-form__section">
            <div class="registration-form__title section-label">
              Role
            </div>
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.name}"
                          :error="validation.name"
                          :errorTxt="serverError ? validationTxt.name : 'This field is required'"
                          :label="'Name'"
                          :type="'text'"
                          v-model="name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.description}"
                          :error="validation.description"
                          :errorTxt="serverError ? validationTxt.description : 'This field is required'"
                          :label="'description'"
                          :type="'text'"
                          v-model="description"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.display_name}"
                          :error="validation.display_name"
                          :errorTxt="serverError ? validationTxt.display_name : 'This field is required'"
                          :label="'display_name'"
                          :type="'text'"
                          v-model="display_name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <MultiSelect
                          v-bind:class="{'ui-no-valid': validation.permissions}"
                          :error="validation.permissions"
                          :errorTxt="serverError ? validationTxt.permissions : 'This field is required'"
                          :options="permissionsOptions"
                          :optionsLabel="'display_name'"
                          :selected="permissions"
                          :label="'permissions'"
                          @change="changePermissions"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <MultiSelect
                          :options="permission_groupOptions"
                          :optionsLabel="'name'"
                          :label="'Permission group'"
                          @change="changeSelect"
                          :selected="permission_group"
                  />
                </div>
              </div>
            </div>
          </div>


        </template>



        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="$store.getters.GET_PATHS.settingsRoles"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                      class="detail-page__footer-btn-i"
                      :value="'Change'"
                      @click.native="changeRole"
                      v-bind:class="{'disabled-btn' : $store.getters.getRoleBtn}"
              />
            </div>
          </div>
        </template>


      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
  import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'

  import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

  import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
  import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'
  import MultiSelect from '../../../../../UI/selectiones/MultiSelect/MultiSelect.vue'

  import {validation} from "../../../../../../services/validation";



  var _ = require('lodash');

  export default {
    name: "RolesCardPage",


    components: {
      CardLeftBlock,
      CardRightBlock,
      DefaultInput,
      LinkBack,
      MainButton,
      MultiSelect,
    },



    data() {

      return {
        successForm: false,
        errorForm: false,
        serverError: false,

        roleId: '',
        name: '',
        description: '',
        display_name: '',
        permissions: [],
        permissionsOptions: [],
        permission_group: '',
        permission_groupOptions: [],


        validation: {
          name: false,
          description: false,
          display_name: false,
          permissions: false,
        },

        validationTxt: {
          name: false,
          description: false,
          display_name: false,
          permissions: false,
        },

      }
    },

    mounted() {

      this.$store.dispatch('fetchPermissionsGroups').then(() => {
        this.permission_groupOptions = this.$store.getters.getPermissionsGroups
      })

      this.$store.dispatch('fetchPermissions').then(() => {
        this.permissionsOptions = this.$store.getters.getPermissions;
      })

      this.$store.dispatch('fetchRoleItem', this.$route.params.id).then(() => {
        let roleItem = this.$store.getters.getRoleItem
        this.name = roleItem.name
        this.description = roleItem.description
        this.display_name = roleItem.display_name
        this.permissions = roleItem.permissions

        this.permissions.map((item) => {
          item.handle = true;
        })
      })

      this.roleId = this.$route.params.id;
    },

    methods: {

      changePermissions(items) {
        this.permissions = items
      },

      changeSelect(items) {
        this.permission_group = items;

        let permission_group_ids = _(items)
          .map('id')
          .value()

        if(permission_group_ids.length === 0){
          let permissions = _.cloneDeep(this.permissions)
          let indexDelete = 0

          this.permissions.map((item, index) => {
            if(Object.prototype.hasOwnProperty.call(item, 'filtered')){
              permissions.splice(index - indexDelete, 1)
              indexDelete = indexDelete + 1
            }
          })

          this.permissions = permissions
          return
        }

        // Set query URL
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('example')
        myQuery.whereIn('permissionGroups.id', permission_group_ids)
        url = url + myQuery.url().split('?')[1]


        this.$store.dispatch('getPermissions', url).then((response) => {
          let responsePermissions = response
          responsePermissions.map((item) => {
            item.filtered = true;
          })

          let permissions = _.cloneDeep(this.permissions)
          let indexDelete = 0

          this.permissions.map((item, index) => {
            if(!Object.prototype.hasOwnProperty.call(item, 'handle')){
              permissions.splice(index - indexDelete, 1)
              indexDelete = indexDelete + 1
            }
          })

          responsePermissions.map((item) => {
            if(_.find(permissions, {id: item.id}) === undefined) {
              permissions.push(item);
            }
          })

          this.permissions = permissions
        })
      },


      changeRole() {
        let validationItems = {
          name: this.name,
          description: this.description,
          display_name: this.display_name,
        }

        let validationOptions = {
          name: {
            type: [
              'empty',
            ]
          },
          description: {
            type: [
              'empty',
            ]
          },
          display_name: {
            type: [
              'empty',
              'display_name',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        if (validate.isValidate) {
          this.serverError = false;

          let permissionsIds = [];

          this.permissions !== null && this.permissions.map((item) => {
            permissionsIds.push(item.id);
          })

          let data = {
            'name': this.name,
            'description': this.description,
            'display_name': this.display_name,
            'permissions': permissionsIds,
          };

          this.$store.commit('changeRole');


          this.$store.dispatch('updateRoles', {id: this.roleId, data: data}).then((response) => {

            if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$store.commit('successRole');
              this.openNotify('success', 'common_notificationRecordChanged')

              setTimeout(() => {
                this.$router.push(this.$store.getters.GET_PATHS.settingsRoles)
              }, 500)


            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;
              this.$store.commit('successRole');

              errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
              errors.description ? (this.validation.description = true, this.validationTxt.description = errors.description[0]) : false;
              errors.display_name ? (this.validation.display_name = true, this.validationTxt.display_name = errors.display_name[0]) : false;
            }

            this.$store.commit('successRole');

          });

        }

      },

    }

  }
</script>

<style lang="scss">

</style>
