<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item">
        <DefaultInput
                :label="'ID'"
                :type="'text'"
                v-model="orderId"
        />
      </div>
      <div class="table-filter__item">
        <DefaultInput
                :label="'Name'"
                :type="'text'"
                v-model="orderId"
        />
      </div>
      <div class="table-filter__item">
        <DefaultInput
                :label="'Role'"
                :type="'text'"
                v-model="orderId"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton :value="$t('common_resetFilters.localization_value.value')"  class="secondary wfc"/>
      <MainButton :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import MainButton from '../../../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";

  export default {
    name: "UsersFilter",

    components: {
      MainButton,
      DefaultInput,
      FilterBlock
    },

    props: [
      'showFilter',
    ],

    data() {
      return {
        orderId: '',
      }
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss">

</style>
