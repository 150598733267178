<template>
  <div>
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs">
        <div class="content-tabs__item active">
          <div class="content-tabs__ico">
            Users
          </div>
        </div>
      </div>

    <ToggleFilterButton @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />

    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.settingsTranslationsGroupCreate">
          <MainButton class="btn-fit-content" :value="'New Translations Group'"/>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
  import MainButton from '../../../../../../UI/buttons/MainButton/MainButton.vue'
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";

  export default {
    name: "TranslationsGroupHead",

    components: {
      MainButton,
      ToggleFilterButton,
    },

    props: [
      'showFilter',
    ],

    methods: {

    }
  }
</script>

<style lang="scss">

</style>
