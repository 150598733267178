<template>
  <div>
    <TranslationsGroupHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <TranslationsGroupFilter :showFilter="showFilter" @close="closeFilter"/>
      <TranslationsGroupTable @getTranslationsGroup="getTranslationsGroup"/>
    </div>
  </div>

</template>

<script>
  import TranslationsGroupHead from './TranslationsGroupHead/TranslationsGroupHead.vue'
  import TranslationsGroupFilter from './TranslationsGroupFilter/TranslationsGroupFilter.vue'
  import TranslationsGroupTable from './TranslationsGroupTable/TranslationsGroupTable.vue'

  export default {
    name: "TranslationsGroupTablePage",

    components: {
      TranslationsGroupHead,
      TranslationsGroupFilter,
      TranslationsGroupTable,
    },

    data() {
      return {
        showFilter: false,
      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.$store.dispatch('fetchTranslationsGroups', '?page=1&limit=1000')
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getTranslationsGroup() {
        this.$store.dispatch('fetchTranslationsGroups')
      }

    }
  }
</script>

<style scoped>

</style>
