import Language from '../Langueage/Language.vue'

export default {
	name: "Successful",
	components: {
		Language,
	},

	data(){
		return {
			successfulRegistrationTranslates: [
				'registration_thankYou',
				'registration_thankYouText',
				'registration_noLinkOnEmail',
				'registration_noLinkOnEmailItemOne',
				'registration_noLinkOnEmailItemTwo',
				'registration_noLinkOnEmailItemThree',
				'registration_supportContact',
				'registration_supportContactMail',
			]
		}
	}
}
