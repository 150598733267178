<template>
  <div>
    <PermissionsHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <PermissionsFilter :showFilter="showFilter" @close="closeFilter"/>
      <PermissionsTable @getPermissions="getPermissions"/>
    </div>
  </div>

</template>

<script>
  import PermissionsHead from './PermissionsHead/PermissionsHead.vue'
  import PermissionsFilter from './PermissionsFilter/PermissionsFilter.vue'
  import PermissionsTable from './PermissionsTable/PermissionsTable.vue'

  export default {
    name: "PermissionsTablePage",

    components: {
      PermissionsHead,
      PermissionsFilter,
      PermissionsTable,
    },

    data() {
      return {
        showFilter: false,
      }
    },

    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {

      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      }

      this.$store.dispatch('fetchPermissions')
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getPermissions() {
        this.$store.dispatch('fetchPermissions')
      }

    }
  }
</script>

<style scoped>

</style>
