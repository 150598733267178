<template>
  <div>
    <PermissionsGroupHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <PermissionsGroupFilter :showFilter="showFilter" @close="closeFilter"/>
      <PermissionsGroupTable @getPermissionsGroup="getPermissionsGroup"/>
    </div>
  </div>

</template>

<script>
  import PermissionsGroupHead from './PermissionsGroupHead/PermissionsGroupHead.vue'
  import PermissionsGroupFilter from './PermissionsGroupFilter/PermissionsGroupFilter.vue'
  import PermissionsGroupTable from './PermissionsGroupTable/PermissionsGroupTable.vue'

  export default {
    name: "PermissionsGroupTablePage",

    components: {
      PermissionsGroupHead,
      PermissionsGroupFilter,
      PermissionsGroupTable,
    },

    data() {
      return {
        showFilter: false,
      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }
      this.$store.dispatch('fetchPermissionsGroups')
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getPermissionsGroup() {
        this.$store.dispatch('fetchPermissionsGroups')
      }

    }
  }
</script>

<style scoped>

</style>
