<template>
  <div>
    <VueComponentsHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <VueComponentsFilter
              :showFilter="showFilter"
              @close="closeFilter"
              @changeFilter="changeFilter"
              @resetFilter="resetFilter"
              :filterId="filterId"
              :filterName="filterName"
      />
      <VueComponentsTable @getVueComponents="getVueComponents"/>


    </div>
  </div>

</template>

<script>
  import VueComponentsHead from './VueComponentsHead/VueComponentsHead.vue'
  import VueComponentsFilter from './VueComponentsFilter/VueComponentsFilter.vue'
  import VueComponentsTable from './VueComponentsTable/VueComponentsTable.vue'

  // const { Query } = require("cogent-js/src/index");


  export default {
    name: "RolesTablePage",

    components: {
      VueComponentsHead,
      VueComponentsFilter,
      VueComponentsTable,
    },

    data() {
      return {
        showFilter: false,
        filterId: '',
        filterName: '',
        forPage: 10,
        page: 1,
      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.filter()
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getVueComponents() {
        this.$store.dispatch('fetchVueComponents')
      },

      changeFilter(data) {
        this.filterId = data.filterId
        this.filterName = data.filterName

        this.filter()
      },

      resetFilter() {
        this.filterId = ''
        this.filterName = ''

        this.filter()
      },

      filter() {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        if(this.filterId !== '') myQuery.where('VueComponentId', this.filterId)
        if(this.filterName !== '') myQuery.where('VueComponentName', this.filterName)

        url = url + myQuery.limit(this.forPage).page(this.page).url().split('?')[1]

        this.$store.dispatch('fetchVueComponents', url)
      }

    }
  }
</script>

<style scoped>

</style>
