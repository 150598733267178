var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-body google-auth"},[_c('SimpleAlert',{staticClass:"auth-body__alert",attrs:{"color":'green',"type":'success',"text":_vm.$t('login_emailVerified.localization_value.value')}}),_c('div',{staticClass:"auth-body__form"},[_c('div',{staticClass:"auth-form",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'login_emailVerified',
          'profile_verifyYourEmail',
          'profile_pleaseEnterYourCode',
          'profile_verificationCode',
          'profile_toRequestNewCode',
          'profile_requestCodeAgain',
          'profile_sec',
          'profile_Send',
        ])}}}),_c('div',{staticClass:"auth-form__inner"},[_c('div',{staticClass:"auth-form__title"},[_vm._v(" "+_vm._s(_vm.$t('profile_verifyYourEmail.localization_value.value'))+" ")]),_c('div',{staticClass:"auth-form__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('profile_pleaseEnterYourCode.localization_value.value'))+" ")]),_c('div',{staticClass:"auth-form__field"},[_c('WhiteInput',{class:{'ui-no-valid': _vm.validation.code},attrs:{"error":_vm.validation.code,"errorTxt":_vm.validationTxt.code,"label":_vm.$t('profile_verificationCode.localization_value.value')},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('div',{staticClass:"auth-form__right-link"},[(_vm.requestCodeAgain == true)?[_vm._v(" "+_vm._s(_vm.$t('profile_toRequestNewCode.localization_value.value'))+" 200"+_vm._s(_vm.$t('profile_sec.localization_value.value'))+". ")]:_vm._e(),(_vm.resend )?_c('span',{staticClass:"site-link"},[_vm._v(" "+_vm._s(_vm.$t('profile_requestCodeAgain.localization_value.value'))+" ")]):_vm._e(),(!_vm.resend)?_c('span',{},[_vm._v(" "+_vm._s(_vm.$t('profile_toRequestNewCode.localization_value.value'))+" "+_vm._s(_vm.time)+" "+_vm._s(_vm.$t('profile_sec.localization_value.value'))+". ")]):_vm._e()],2),_c('div',{staticClass:"auth-form__btn"},[_c('div',{staticClass:"auth-form__btn-login"},[_c('FullButton',{staticClass:"google-auth__email-btn",class:{'disabled-btn' : _vm.$store.getters.getToFactorCodeBtn},attrs:{"value":_vm.$t('profile_Send.localization_value.value')}})],1)])])]),_c('div',{staticClass:"auth-form",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'profile_addGoogleAuth',
          'profile_addGoogleAuthTxt',
        ])}}}),_c('div',{staticClass:"auth-form__inner"},[_c('div',{staticClass:"auth-form__title"},[_vm._v(" "+_vm._s(_vm.$t('profile_addGoogleAuth.localization_value.value'))+" ")]),_c('div',{staticClass:"auth-form__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('profile_addGoogleAuthTxt.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['common_goToDashboard'])}}}),_c('MainButton',{staticClass:"wfc",attrs:{"value":_vm.$t('common_goToDashboard.localization_value.value')}})],1)]),_c('div',{staticClass:"custom-col custom-col--50",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['menu_Profile'])}}}),_c('MainButton',{staticClass:"wfc",attrs:{"value":_vm.$t('menu_Profile.localization_value.value')}})],1)])])])]),_c('BottomLine')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }