import WhiteInput from '../../../../../UI/inputs/WhiteInput/WhiteInput'
import FullButton from '../../../../../UI/buttons/FullButtons/FullButton'
import Fb from '../../../../../../../public/img/login-group/fb.svg?inline'
import BottomLine from "../../../../../coreComponents/BottomLine/BottomLine.vue";
import ForgotComplete from './ForgotComplete/ForgotComplete.vue'


import {validation} from "../../../../../../services/validation";
var _ = require('lodash');




export default {
  name: "Forgot",

  components: {
    WhiteInput,
    Fb,
    FullButton,
    BottomLine,
    ForgotComplete,
  },

  data() {
    return{
      errorForm: false,
      forgotComplete: false,
      email: '',
      serverError: false,

      validation: {
        email: false,
        password: false
      },

      validationTranslate: {
        email: '',
      },

      validationTxt: {
        email: false,
        password: false
      },
    }
  },

  methods: {
    forgotSubmit() {
      let validationItems = {
        email: this.email,
      }

      let validationOptions = {
        email: {
          type: [
            'email',
            'empty',
          ]
        },

      }
      let validate = validation(validationItems, validationOptions);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if(validate.isValidate){
        this.serverError = false;
        this.$store.dispatch('forgot', validationItems).then((response) => {

          if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.forgotComplete = !this.forgotComplete
          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.serverError = true;

            errors.email ? ( this.validation.email = true, this.validationTxt.email = errors.email[0] ) : false;
          }

        })
      }

    },
  }

}
