<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'Vue Component Id'" :value="'#1'" />
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

        <template slot="header">
          <div class="detail-page__head">
            <div class="detail-page__head-line">
              <div class="detail-page__header-col">
                <router-link :to="$store.getters.GET_PATHS.settingsVueComponents" class="detail-page__head-back">
                  <LinkBack
                          :value="'Back to Vue Components'"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="registration-form__section">
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.name}"
                          :error="validation.name"
                          :errorTxt="serverError ? validationTxt.name : 'This field is required'"
                          :label="'Name'"
                          :type="'text'"
                          v-model="name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.description}"
                          :error="validation.description"
                          :errorTxt="serverError ? validationTxt.description : 'This field is required'"
                          :label="'description'"
                          :type="'text'"
                          v-model="description"
                  />
                </div>
              </div>
            </div>
          </div>


        </template>



        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="$store.getters.GET_PATHS.settingsVueComponents"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                      class="detail-page__footer-btn-i"
                      :value="'Create'"
                      @click.native="createVueComponent"
                      v-bind:class="{'disabled-btn' : $store.getters.getVueComponentBtn}"
              />
            </div>
          </div>
        </template>


      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
  import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'

  import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

  import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
  import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'

  import {validation} from "../../../../../../services/validation";



  var _ = require('lodash');

  export default {
    name: "VueComponentsCreate",


    components: {
      CardLeftBlock,
      CardRightBlock,
      DefaultInput,
      LinkBack,
      MainButton,
    },



    data() {

      return {
        successForm: false,
        errorForm: false,
        serverError: false,

        name: '',
        description: '',
        display_name: '',


        validation: {
          name: false,
          description: false,
        },

        validationTxt: {
          name: false,
          description: false,
        },

      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }


    },

    methods: {

      createVueComponent() {
        let validationItems = {
          name: this.name,
          description: this.description,
        }

        let validationOptions = {
          name: {
            type: [
              'empty',
            ]
          },
          description: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        if (validate.isValidate) {
          this.serverError = false;


          let data = {
            'name': this.name,
            'description': this.description,
          };

          this.$store.commit('changeVueComponent');


          this.$store.dispatch('createVueComponents', data).then((response) => {

            if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$store.commit('successVueComponent');

            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.$store.commit('successVueComponent');
              this.serverError = true;

              errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
            }

          });

        }

      },

    }

  }
</script>

<style lang="scss">

</style>
