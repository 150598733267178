import Language from '../Langueage/Language.vue'
import Fb from '../../../../../../../../public/img/login-group/fb.svg?inline'
import DefaultInput from '../../../../../../UI/inputs/DefaultInput/DefaultInput.vue'
import DefaultCheckbox from '../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox.vue'
import LargeSwitch from '../../../../../../UI/radiobuttons/LargeSwitch/LargeSwitch.vue'
import MainButton from '../../../../../../UI/buttons/MainButton/MainButton.vue'
import DefaultSelect from '../../../../../../UI/selectiones/DefaultSelect/DefaultSelect.vue'

import VFacebookLogin from 'vue-facebook-login-component'

import { VTooltip, VPopover  } from 'v-tooltip'
import {validation} from "../../../../../../../services/validation"
import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn.vue"
import {filterCountries} from "../../../../../../../services/filterCountries";
import PasswordRequirements from "@/components/coreComponents/PasswordRequirements/PasswordRequirements";


export default {
  name: "SignUpForm",

  components:{
    Language,
    Fb,
    DefaultInput,
    DefaultCheckbox,
    LargeSwitch,
    MainButton,
    DefaultSelect,
    VTooltip,
    VPopover,
    TooltipBtn,
    VFacebookLogin,
    PasswordRequirements,
  },

  data(){

    return{

      constValue: {
        AMAZON: 'amazon',
        MERCHANT: 'merchant',
        FUNCTIONALITY: {
          FULL: 'full',
          EASY: 'easy',
        },
      },

      successForm: false,
      errorForm: false,
      serverError: false,

      useFB: false,
      FB: {},
      model: {},
      scope: {},
      fbId: '',

      name: '',
      surName: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      country: '',
      postNumber: '',
      loginShop : '',
      createPassword: '',
      checkedAmazon: false,
      checkedMerchant: false,
      checkedFunctionality: 'full',
      registrationAgree: false,
      countrySelect: '',
      countrySelectModel: '',
      selectedCountry: '',
      stateDistrict: '',
      tooltipFunctionalityActive: true,

      validation: {
        name: false,
        surName:false,
        phone:false,
        email: false,
        createPassword: false,
        address:false,
        city:false,
        selectedCountry: false,
        registrationAgree: false,
        stateDistrict: false,
        postNumber: false,
        loginShop: false,
      },

      validationTranslate: {
        name: 'default',
        surName:'default',
        phone:'default',
        email: 'default',
        createPassword: 'default',
        address:'default',
        city:'default',
        selectedCountry: 'default',
        registrationAgree: 'default',
        stateDistrict: 'default',
        postNumber: 'default',
        loginShop: 'default',
      },

      validationTxt: {
        name: false,
        surName:false,
        phone:false,
        email: false,
        createPassword: false,
        address:false,
        city:false,
        selectedCountry: false,
        registrationAgree: false,
        stateDistrict: false,
        postNumber: false,
        loginShop: false,
      },

      customValidation: {
        fullFilment: false,
      },

      options: [{}],

      optionsDistrict: [{}],
    }
  },

  mounted() {
    this.$store.dispatch('fetchCountries').then(() => {
      let countries = this._.toArray(this.$store.getters.getCountries)
      let filteredCountries = filterCountries(countries)

      this.options = filteredCountries.countries
      this.selectedCountry = filteredCountries.country.ua.item

      this.$store.dispatch('getRegionsByCountry', filteredCountries.country.ua.item.id).then(() => {
        this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
      });
    })

  },

  methods: {
    changeCountry(val) {
      this.selectedCountry = val;
      console.log(val);

      this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
        this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
      });
    },

    changeDistrict(val) {
      this.stateDistrict = val;
    },

    changeFunctionality(val){
      this.checkedFunctionality = val;
    },

    registration(e) {
      e.preventDefault();
      let validationItems = {
        name: this.name,
        surName: this.surName,
        phone: this.phone,
        email: this.email,
        createPassword: this.createPassword,
        address: this.address,
        city: this.city,
        selectedCountry: this.selectedCountry,
        registrationAgree: this.registrationAgree,
        stateDistrict: this.stateDistrict,
        postNumber: this.postNumber,
        loginShop: this.loginShop,
      }


      let validationOptions = {
        name: {
          type: [
            'latin',
            'empty',
          ]
        },
        surName: {
          type: [
            'latin',
            'empty',
          ]
        },
        phone: {
          type: [
            'phone',
            'empty',
          ]
        },
        email: {
          type: [
            'email',
            'empty',
          ]
        },
        createPassword: {
          type: [
            'latin',
            'password',
            'empty',
          ]
        },
        address: {
          type: [
            'latin',
            'empty',
          ]
        },
        city: {
          type: [
            'empty',
          ]
        },
        selectedCountry: {
          type: [
            'empty',
          ]
        },
        registrationAgree: {
          type: [
            'checked'
          ]
        },
        stateDistrict: {
          type: [
            'empty',
          ]
        },
        postNumber: {
          type: [
            'numeric',
            'empty',
          ]
        },
        loginShop: {
          type: [
            'empty',
          ]
        },
      };

      let validate = validation(validationItems, validationOptions);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if(!this.checkedAmazon && !this.checkedMerchant){
        this.customValidation.fullFilment = true;
      } else {
        this.customValidation.fullFilment = false;
      }

      if(validate.isValidate && !this.customValidation.fullFilment) {
        this.serverError = false;

        let data = {
          'name': this.name,
          'last_name': this.surName,
          'phone': this.phone,
          'email': this.email,
          'password': this.createPassword,
          'address': this.address,
          'city': this.city,
          'country_id': this.selectedCountry.id,
          'country_region_id': this.stateDistrict.id,
          'zip': this.postNumber,
          'company_name': this.loginShop,
          'fulfilment_by_amazon': this.checkedAmazon ? 1 : 0,
          'fulfilment_by_merchant': this.checkedMerchant ? 1 : 0,
          'terms': 1,
        };

        if(this.fbId !== ''){
          data.facebook_id = this.fbId
        }

        this.$store.dispatch('registration', data).then((response) => {

          if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.$emit('successRegister');
          } else if(response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.serverError = true;

            errors.name ?       ( this.validation.name = true,            this.validationTxt.name = errors.name[0] )                  : false;
            errors.last_name ?  ( this.validation.surName = true,         this.validationTxt.surName = errors.last_name[0] )          : false;
            errors.phone ?      ( this.validation.phone = true,           this.validationTxt.phone = errors.phone[0] )                : false;
            errors.email ?      ( this.validation.email = true,           this.validationTxt.email = errors.email[0] )                : false;
            errors.password ?   ( this.validation.createPassword = true,  this.validationTxt.createPassword = errors.password[0] )    : false;
            errors.address ?    ( this.validation.address = true,         this.validationTxt.address = errors.address[0] )            : false;
            errors.city ?       ( this.validation.city = true,            this.validationTxt.city = errors.city[0] )                  : false;
            errors.country_id ? ( this.validation.selectedCountry = true, this.validationTxt.selectedCountry = errors.country_id[0] ) : false;
            errors.company_name ?  ( this.validation.loginShop = true,       this.validationTxt.loginShop = errors.company_name[0])      : false;

            // this.errorForm = this._.toArray(response.response.data.data.errors);
          }

        });

      }

    },

    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },

    loginFb() {
      this.getUserData()
    },

    getUserData() {
      this.FB.api(
        '/me',
        { fields: 'id, name, picture, email' },
        (user) => {
          this.useFB = true;
          this.name = user.name.split(' ')[0];
          this.surName = user.name.split(' ')[1];
          this.email = user.email;
          this.fbId = user.id
        }
      )
    },

  }
}
