
import WhiteInput from '../../../../../UI/inputs/WhiteInput/WhiteInput'
import FullButton from '../../../../../UI/buttons/FullButtons/FullButton'
import SimpleAlert from '../../../../../UI/alerts/SimpleAlert/SimpleAlert'
import Fb from '../../../../../../../public/img/login-group/fb.svg?inline'

import VFacebookLogin from 'vue-facebook-login-component'

import {validation} from '../../../../../../services/validation'
import BottomLine from "../../../../../coreComponents/BottomLine/BottomLine.vue";
var _ = require('lodash');


export default {
  name: "Login",

  components: {
    WhiteInput,
    Fb,
    BottomLine,
    FullButton,
    SimpleAlert,
    VFacebookLogin,
  },

  data() {
    return {
      email: '',
      password: '',
      openAlert: false,
      openErrorAlert: false,
      errorForm: false,
      serverError: false,

      FB: {},
      model: {},
      scope: {},

      validation: {
        email: false,
        password: false
      },

      validationTranslate: {
        email: '',
        password: ''
      },

      validationTxt: {
        email: false,
        password: false
      },
    }
  },

  mounted() {
    if(location.hash.length > 0){
      this.$store.dispatch('verifyUser', location.hash.substring(1)).then((response) => {

        if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
          this.openAlert = true;
        } else if(response.response.status === this.$store.getters.GET_ERRORS.BAD_REQUEST_CODE) {
          this.openErrorAlert = true;
        }

      });
    }



  },

  methods: {

    closeAlert() {
      this.openAlert = false;
      this.openErrorAlert = true;
    },

    auth(e) {
      e.preventDefault();
      let validationItems = {
        email: this.email,
        password: this.password,
      };

      let validationOptions = {
        email: {
          type: [
            'email',
            'empty',
          ]
        },
        password: {
          type: [
            'password',
            'empty',
          ]
        },
      };

      let validate = validation(validationItems, validationOptions)
      this.validation = validate.validation
      this.validationTranslate = validate.validationError

      console.log(validate);
      if(validate.isValidate){
        this.serverError = false;

        this.$store.dispatch('auth', validationItems).then((response) => {

          if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.$router.push(this.$store.getters.GET_PATHS.dashboard);
          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;


            this.serverError = true;
            errors.email ?      ( this.validation.email = true,     this.validationTxt.email = errors.email[0] )          : false;
            errors.password ?   ( this.validation.password = true,  this.validationTxt.password = errors.password[0] )    : false;

          }

        })
      }
    },


    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },

    loginFb() {
      this.getUserData()
    },

    getUserData() {
      this.FB.api(
        '/me',
        { fields: 'id, name, picture, email' },
        (user) => {
          this.user = user
          console.log(user)
        }
      )
    },

  },


}
