<template>
  <div>

<!--    <template v-if="isAdmin">-->
<!--      <EmailAuthenticationAdmin/>-->
<!--    </template>-->

    <EmailAuthenticationUser/>
  </div>
</template>

<script>
  import EmailAuthenticationUser from "./EmailAuthenticationUser/EmailAuthenticationUser.vue";

  export default {
    name: "EmailAuthentication",
    components: {
      EmailAuthenticationUser,
    },
  }

</script>

<style lang="scss">

</style>
