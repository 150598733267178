<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'Permission Id'" :value="'#1'" />
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

          <template slot="header">
            <div class="detail-page__head">
              <div class="detail-page__head-line">
                <div class="detail-page__header-col">
                  <router-link :to="$store.getters.GET_PATHS.settingsPermissions" class="detail-page__head-back">
                    <LinkBack
                            :value="'Back to Permissions'"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </template>

        <template slot="body">
          <div class="registration-form__section">
            <div class="registration-form__title section-label">
              Permission
            </div>
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.name}"
                          :error="validation.name"
                          :errorTxt="serverError ? validationTxt.name : 'This field is required'"
                          :label="'Name'"
                          :type="'text'"
                          v-model="name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.display_name}"
                          :error="validation.display_name"
                          :errorTxt="serverError ? validationTxt.display_name : 'This field is required'"
                          :label="'display_name'"
                          :type="'text'"
                          v-model="display_name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.description}"
                          :error="validation.description"
                          :errorTxt="serverError ? validationTxt.description : 'This field is required'"
                          :label="'description'"
                          :type="'text'"
                          v-model="description"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <MultiSelect
                          :options="permission_groupOptions"
                          :optionsLabel="'name'"
                          :label="'Permission group'"
                          @change="changeSelect"
                          :selected="permission_group"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultSelect
                          :options="vueComponentOptions"
                          :optionsLabel="'name'"
                          :label="'Vue Component'"
                          @change="changeSelectVue"
                          :selected="vueComponent"
                  />
                </div>
              </div>
            </div>
          </div>


        </template>



        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="$store.getters.GET_PATHS.settingsPermissions"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                      class="detail-page__footer-btn-i"
                      :value="'Change'"
                      @click.native="change"
                      v-bind:class="{'disabled-btn' : $store.getters.getPermissionBtn}"
              />
            </div>
          </div>
        </template>


      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
  import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'

  import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

  import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
  import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultSelect from '../../../../../UI/selectiones/DefaultSelect/DefaultSelect.vue'

  import {validation} from "../../../../../../services/validation";
  import MultiSelect from "../../../../../UI/selectiones/MultiSelect/MultiSelect";



  var _ = require('lodash');

  export default {
    name: "PermissionsCard",


    components: {
      CardLeftBlock,
      CardRightBlock,
      DefaultInput,
      LinkBack,
      MainButton,
      DefaultSelect,
      MultiSelect,
    },



    data() {

      return {
        successForm: false,
        errorForm: false,
        serverError: false,

        permissionId: '',
        name: '',
        description: '',
        display_name: '',
        permission_group: '',
        permission_groupOptions: [],
        vueComponent: '',
        vueComponentOptions: [],


        validation: {
          name: false,
          description: false,
          display_name: false,
        },

        validationTxt: {
          name: false,
          description: false,
          display_name: false,
        },

      }
    },

    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.$store.dispatch('fetchPermissionsGroups').then(() => {
        this.permission_groupOptions = this.$store.getters.getPermissionsGroups
      })

      this.$store.dispatch('fetchVueComponents').then(() => {
        this.vueComponentOptions = this.$store.getters.getVueComponents
      })

      this.permissionId = this.$route.params.id;
    },

    methods: {

      changeSelect(items) {
        this.permission_group = items;
      },

      changeSelectVue(items) {
        this.vueComponent = items;
      },

      change() {
        let validationItems = {
          name: this.name,
          description: this.description,
          display_name: this.display_name,
        }

        let validationOptions = {
          name: {
            type: [
              'empty',
            ]
          },
          description: {
            type: [
              'empty',
            ]
          },
          display_name: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        if (validate.isValidate) {
          this.serverError = false;

          let permissionsGroupIds = [];

          this.permission_group !== null && this.permission_group.map((item) => {
            permissionsGroupIds.push(item.id);
          })

          let data = {
            'name': this.name,
            'description': this.description,
            'display_name': this.display_name,
            'permission_group_id': permissionsGroupIds,
            'vue_component_id': this.vueComponent !== null ? this.vueComponent.id : '',
          };

          this.$store.commit('changeBtn');


          this.$store.dispatch('createPermissions', data).then((response) => {

            if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$store.commit('successBtn');

            } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;

              errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
              errors.description ? (this.validation.description = true, this.validationTxt.description = errors.description[0]) : false;
              errors.display_name ? (this.validation.display_name = true, this.validationTxt.display_name = errors.display_name[0]) : false;
            }

          })

        }

      },

    }

  }
</script>

<style lang="scss">

</style>
