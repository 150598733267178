<template>

  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <!--<div class="table-filter__item">-->
        <!--<DefaultInput-->
                <!--:label="'ID'"-->
                <!--:type="'text'"-->
                <!--v-model="id"-->
        <!--/>-->
      <!--</div>-->
      <div class="table-filter__item">
        <DefaultInput
                :label="'Name'"
                :type="'text'"
                v-model="key"
                @keyup.enter.native="changeFilter"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>

</template>

<script>
  import MainButton from '../../../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";


  export default {
    name: "TranslationsFilter",

    components: {
      MainButton,
      DefaultInput,
      FilterBlock
    },

    props: [
      'showFilter',
      'filterId',
      'filterKey',
    ],

    data() {
      return {
        id: '',
        key: '',
      }
    },

    watch: {
      filterId: function(newVal) {
        this.id = newVal
      },

      filterKey: function(newVal) {
        this.key = newVal
      },
    },

    mounted() {
      // this.filterId = this.id;
      // this.filterKey = this.key;
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },


      resetFilter() {
        this.$emit('resetFilter')
      },

      changeFilter() {

        let data = {
          filterKey: this.key,
        }
        this.$emit('changeFilter', data)
      }
    },
  }
</script>

<style lang="scss">

</style>
