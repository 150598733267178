<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock :name="'Permission Group Id'" :value="'Create'" />
    </div>
    <div class="detail-page__right">
      <CardRightBlock>

          <template slot="header">
            <div class="detail-page__head">
              <div class="detail-page__head-line">
                <div class="detail-page__header-col">
                  <router-link :to="$store.getters.GET_PATHS.settingsPermissionsGroup" class="detail-page__head-back">
                    <LinkBack
                            :value="'Back to Permissions Group'"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </template>

        <template slot="body">
          <div class="registration-form__section">
            <div class="registration-form__row custom-row">
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.name}"
                          :error="validation.name"
                          :errorTxt="serverError ? validationTxt.name : 'This field is required'"
                          :label="'Name'"
                          :type="'text'"
                          v-model="name"
                  />
                </div>
              </div>
              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': validation.description}"
                          :error="validation.description"
                          :errorTxt="serverError ? validationTxt.description : 'This field is required'"
                          :label="'description'"
                          :type="'text'"
                          v-model="description"
                  />
                </div>
              </div>

              <div class="registration-form__col custom-col custom-col--33">
                <div class="registration-form__input">
                  <DefaultSelect
                          :options="rolesOptions"
                          :optionsLabel="'name'"
                          :label="'Role'"
                          @change="changeSelect"
                          :selected="roles"
                  />
                </div>
              </div>
            </div>
          </div>


        </template>



        <template slot="footer">
          <div class="detail-page__footer">
            <div class="detail-page__footer-link">
              <router-link :to="'/#'"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>
            <div class="detail-page__footer-btn">
              <MainButton
                      class="detail-page__footer-btn-i"
                      :value="'Change'"
                      @click.native="change"
                      v-bind:class="{'disabled-btn' : $store.getters.getPermissionsGroupBtn}"
              />
            </div>
          </div>
        </template>


      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from '../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue'
  import CardRightBlock from '../../../../../coreComponents/CardRightBlock/CardRightBlock.vue'

  import DefaultInput from '../../../../../UI/inputs/DefaultInput/DefaultInput.vue'

  import LinkBack from '../../../../../UI/buttons/LinkBack/LinkBack.vue'
  import MainButton from '../../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultSelect from '../../../../../UI/selectiones/DefaultSelect/DefaultSelect.vue'

  import {validation} from "../../../../../../services/validation";



  var _ = require('lodash');

  export default {
    name: "PermissionsGroupCardCreate",


    components: {
      CardLeftBlock,
      CardRightBlock,
      DefaultInput,
      LinkBack,
      MainButton,
      DefaultSelect,
    },



    data() {

      return {
        successForm: false,
        errorForm: false,
        serverError: false,

        name: '',
        description: '',
        roles: '',
        rolesOptions: [],


        validation: {
          name: false,
          description: false,
        },

        validationTxt: {
          name: false,
          description: false,
        },

      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.$store.dispatch('fetchRoles', this.$route.params.id).then(() => {
        this.rolesOptions = this.$store.getters.getRoles
      })
    },

    methods: {

      changeSelect(items) {
        this.roles = items;
      },

      change() {
        let validationItems = {
          name: this.name,
          description: this.description,
        }

        let validationOptions = {
          name: {
            type: [
              'empty',
            ]
          },
          description: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;

        if (validate.isValidate) {
          this.serverError = false;

          let data = {
            'name': this.name,
            'description': this.description,
            'role_id': this.roles.id,
          };

          this.$store.dispatch('changePermissionsGroup');


          this.$store.dispatch('createPermissionsGroups', data).then((response) => {

            if (_.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$store.dispatch('successPermissionsGroup');

            } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;

              errors.name ? (this.validation.name = true, this.validationTxt.name = errors.name[0]) : false;
              errors.description ? (this.validation.description = true, this.validationTxt.description = errors.description[0]) : false;
            }

          });

        }

      },

    }

  }
</script>

<style lang="scss">

</style>
