<template>
  <div>
    <RolesHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <RolesFilter :showFilter="showFilter" @close="closeFilter"/>
      <RolesTable @getRoles="getRoles"/>
    </div>
  </div>

</template>

<script>
  import RolesHead from './RolesHead/RolesHead.vue'
  import RolesFilter from './RolesFilter/RolesFilter.vue'
  import RolesTable from './RolesTable/RolesTable.vue'

  export default {
    name: "RolesTablePage",

    components: {
      RolesHead,
      RolesFilter,
      RolesTable,
    },

    data() {
      return {
        showFilter: false,
      }
    },


    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.$store.dispatch('fetchRoles')
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      getRoles() {
        this.$store.dispatch('fetchRoles')
      }
    }
  }
</script>

<style scoped>

</style>
