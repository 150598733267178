<template>
  <div>
    <TranslationsHead @toggleFilter="toggleFilter" :showFilter="showFilter"/>
    <div class="table-filter-wrap">
      <TranslationsFilter
              :showFilter="showFilter"
              @close="closeFilter"
              @changeFilter="changeFilter"
              @resetFilter="resetFilter"
              :filterKey="filterKey"
      />
      <TranslationsTable
              @getTranslations="getTranslations"
              @showMore="showMore"
      />

    </div>
  </div>

</template>

<script>
  import TranslationsHead from './TranslationsHead/TranslationsHead.vue'
  import TranslationsFilter from './TranslationsFilter/TranslationsFilter.vue'
  import TranslationsTable from './TranslationsTable/TranslationsTable.vue'

  // const { Query } = require("cogent-js/src/index");

  export default {
    name: "TranslationsTablePage",

    components: {
      TranslationsHead,
      TranslationsFilter,
      TranslationsTable,
    },

    data() {
      return {
        showFilter: false,
        filterKey: '',
        forPage: 100000,
        page: 1,
      }
    },

    watch: {
      loadUserRoleAndAuthorizedData() {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
        }
      },
    },

    mounted() {
      if(this.loadUserRoleAndAuthorizedData){
        if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
            this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
        ) {
          this.$router.push(this.$store.getters.GET_PATHS.dashboard)
          return
        }
      }

      this.filter()

    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {

        this.filterKey = data.filterKey
        this.page = 1

        this.filter()
      },

      resetFilter() {
        // this.filterId = ''
        this.filterKey = ''

        this.filter()
      },

      filter(next = false) {

        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        if(this.filterKey !== '') myQuery.where('Key', this.filterKey)

        url = url + myQuery.limit(this.forPage).page(this.page).url().split('?')[1]

        if(next) this.$store.commit('setNextPage', true)

        this.$store.dispatch('fetchTranslations', url).then(() => {
          this.$store.commit('setNextPage', false)
        })

      },

      getTranslations() {
        this.$store.dispatch('fetchTranslations')
      },

      showMore() {
        this.page = this.page + 1

        this.filter(true)
      },


    }
  }
</script>

<style scoped>

</style>
