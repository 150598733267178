
import Vase from '../../../../public/img/login-group/vase.svg?inline'

export default {
  name: "BottomLine",

  components: {
    Vase,
  },

  data(){
    return{
      activeLang: 'en'
    }
  }
}
