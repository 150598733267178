<template>
  <div>


    <!--<template v-if="$store.getters.getCurrentUserRights.additionalPermissions && $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]">-->
      <!--<GoogleAuthenticationAdmin/>-->
    <!--</template>-->

    <GoogleAuthenticationUser/>


  </div>
</template>

<script>
  import GoogleAuthenticationUser from "./GoogleAuthenticationUser/GoogleAuthenticationUser.vue";


  export default {
    name: "GoogleAuthentication",
    components: {
      GoogleAuthenticationUser
    },

  }

</script>

<style lang="scss">


</style>
