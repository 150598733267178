import Banner from '../Banner/Banner.vue'
import SignUpForm from '../SignUpForm/SignUpForm.vue'
import Successful from '../Successful/Successful.vue'

export default {
  name: "SignUp",

  data(){
    return{
      successForm: false,
    }
  },

  methods:{
    successRegister() {
      this.successForm = true;
    }
  },

  components: {
    Banner,
    SignUpForm,
    Successful
  }
}
