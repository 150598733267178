<template>
  <div>
    <Login/>
    <br><br><br>
    <Forgot/>
    <br><br><br>
    <ForgotReset/>
    <br><br><br>
    <SignUp/>
    <br><br><br>
    <EmailAuthentication/>
    <br><br><br>
    <GoogleAuthentication/>
    <br><br><br>

    <TechnicalWorksModule/>
  </div>
</template>

<script>
  import Forgot from "./Forgot/Forgot.vue";
  import ForgotReset from "./ForgotReset/ForgotReset.vue";
  import Login from "./Login/Login.vue";
  import SignUp from "./Registration/SignUp/SignUp.vue";
  import TechnicalWorksModule from "../../../NotFoundModule/TechnicalWorksModule";
  import EmailAuthentication
    from "@/components/modules/SettingsModule/components/AdminAuth/EmailAuthentication/EmailAuthentication";
  import GoogleAuthentication
    from "@/components/modules/SettingsModule/components/AdminAuth/GoogleAuthentication/GoogleAuthentication";

  export default {
    name: "AdminAuth",

    components: {
      GoogleAuthentication,
      EmailAuthentication,
      Forgot,
      ForgotReset,
      Login,
      SignUp,
      TechnicalWorksModule,
    },


    watch: {
      loadUserAuthorizedData() {
        if(!this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

    mounted() {

      if(this.loadUserAuthorizedData && !this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    },


  }
</script>

<style scoped>

</style>
